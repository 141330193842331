import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { DealPostType } from '@types';
import {
  getOverAllPercentageDiscount,
  getPercentageDiscountFromListPrice
} from '@utils/index';
import { useGetUserSettings, defaultUISettings } from '@hooks/useUserSettings';
import { useGetUserData } from '@hooks/useGetUserData';

interface Props extends DealPostType {
  isDatabaseCard?: boolean;
}

const PercentageDiscount = (props: Props) => {
  const { isDatabaseCard } = props;
  const theme = useTheme();
  const { data: user } = useGetUserData();
  const { data: userSettings } = useGetUserSettings();
  const settingName = 'databasePercentageBasis';
  const setting = user
    ? userSettings?.[settingName]
    : defaultUISettings[settingName];
  const percentDiscount =
    setting === 'LIST_PRICE'
      ? getPercentageDiscountFromListPrice(props)
      : Math.round(getOverAllPercentageDiscount(props));
  if (percentDiscount === null || percentDiscount < 15) {
    return null;
  }

  const tooltipTitle =
    setting === 'LIST_PRICE'
      ? `This discount percentage is based off of the list price provided by Amazon.`
      : `This discount percentage is based off of the current price and takes into account the additional discounts we have found.`;

  return (
    <Tooltip
      enterDelay={250}
      title={`${tooltipTitle} You can change this in your user settings above.`}
      placement="top"
    >
      <Typography
        variant="h6"
        component="span"
        sx={
          isDatabaseCard
            ? {
                fontSize: '13px !important',
                color: `${theme.palette.newColor} !important`,
                fontWeight: '600 !important',
                marginRight: '6px !important',
                lineHeight: '18px !important',
                verticalAlign: 'bottom',
                cursor: 'pointer'
              }
            : {
                fontSize: '13px !important',
                color: theme.palette.greenTextColor,
                fontWeight: '600 !important',
                marginRight: '6px !important',
                lineHeight: '26px !important',
                verticalAlign: 'bottom',
                cursor: 'pointer'
              }
        }
      >
        {isDatabaseCard ? `-${percentDiscount}%` : `${percentDiscount}% off`}
      </Typography>
    </Tooltip>
  );
};

export default PercentageDiscount;
