import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { useAddDeal, useGetDashboard } from '@hooks/useDealAdmin';

const AddProduct = ({
  ASIN,
  threshold
}: {
  ASIN: string;
  threshold: number;
}) => {
  const { data: adminDashboard } = useGetDashboard();
  const [isDisabled, setIsDisabled] = useState(false);
  const { mutate: addDeal } = useAddDeal();

  useEffect(() => {
    const isInDB = !!adminDashboard?.newDeals?.find(
      (deal) => deal.ASIN === ASIN
    );
    if (isInDB) {
      setIsDisabled(true);
    }
  }, [adminDashboard, ASIN]);

  const handleAddClick = () => {
    setIsDisabled(true);
    addDeal({
      ASINs: [
        {
          ASIN,
          price: threshold
        }
      ]
    });
  };

  return (
    <IconButton
      aria-label="add"
      disabled={isDisabled}
      color="primary"
      onClick={handleAddClick}
    >
      <AddIcon />
    </IconButton>
  );
};

export default AddProduct;
