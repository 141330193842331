import React from 'react';
import {
  CircularProgress,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/system';
import { useGetDealListDeals } from '@hooks/useGetDealListDeals';
import SummaryListDeal from '../SummaryListDeal';
import colors from '../../../../utils/colors';

const ListDeals = ({
  currentASIN,
  listId,
  name,
  openExpanded
}: {
  currentASIN: string;
  listId: number;
  name: string;
  openExpanded: boolean;
}) => {
  const theme = useTheme();
  const { data: deals, isLoading } = useGetDealListDeals(listId);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100px"
      >
        <CircularProgress />
      </Box>
    );
  }

  const filteredDeals = (deals || []).filter(
    (deal: any) => deal.ASIN !== currentASIN
  );

  if (!deals || filteredDeals.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: 8
      }}
    >
      <Accordion
        defaultExpanded={openExpanded}
        sx={{
          border: `1px solid ${colors.list}`,
          borderRadius: '4px',
          background: colors.list
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: 'white'
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            background: colors.list,
            borderRadius: '4px'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold'
            }}
            variant="subtitle2"
          >
            More deals on {name}
            {filteredDeals.length > 0
              ? ` (${filteredDeals.length} deal${
                  filteredDeals.length > 1 ? 's' : ''
                })`
              : ''}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: theme.palette.background.paper
          }}
        >
          {(filteredDeals || []).map((deal) => (
            <SummaryListDeal key={deal.ASIN} deal={deal} />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ListDeals;
