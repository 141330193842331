import React from 'react';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import { Grid, Skeleton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router';

import { AppState, DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import Disclaimer from '@components/Disclaimer';
import ScrollTop from '@components/ScrollTop';

import SignupForm from '@pages/Blog/components/SignUpForm';
import config from '@configFile';
import { useGetUserData } from '@hooks/useGetUserData';
import { useDealsState } from './useDealsState';
import DealSortSelect from './components/DealSortSelect';
import DealCard from './components/DealCard';
import DatabaseCard from './components/DatabaseCard';
import SkeletonDealCard from './components/SkeletonDealCard';
import SkeletonDatabaseCard from './components/SkeletonDatabaseCard';
import ReceiptDealContainer from './components/ReceiptDealContainer';
import DatabaseEndMessage from './components/DatabaseEndMessage';
import SearchFilterField from './components/SearchFilterField';
import FilterChips from './components/FilterChips';
import BrandChips from './components/BrandChips';
import DatabaseSettings from './components/DatabaseSettings';
import dealPageConfig from './defaultConfig';
import StrategyChips from './components/StrategyChips';

const emailSignUpWrapperStyles = {
  width: '100%',
  margin: {
    xs: '0px 12px',
    sm: '0px auto'
  }
};

interface DealsProps extends ReturnType<typeof mapStateToProps> {
  isEventDay?: boolean;
}

const Deals = (props: DealsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: user } = useGetUserData();
  const isAdmin = !!user?.isAdmin;
  const { dealSummaryASIN, dealSummary, isEventDay } = props;
  const funcs = useDealsState(isEventDay, dealSummaryASIN);
  const {
    sort,
    onlyCoupons,
    isFiltering,
    setIsFiltering,
    searchFilter,
    setSearchFilter,
    strategyFilter,
    setStrategyFilter,
    amount,
    dealsWithASIN,
    latestDealsLoading,
    shouldShowV2Layout,
    tagData,
    unfilteredDealsWithASIN,
    toggleOnlyCoupons,
    resetAll,
    loadMore,
    handleSortChange,
    hasFilters
  } = funcs;

  const CardComponent = shouldShowV2Layout ? DatabaseCard : DealCard;
  const infiniteContainerStyles = shouldShowV2Layout
    ? ({
        marginLeft: '-17px',
        marginRight: '-17px',
        display: 'flex',
        flexWrap: 'wrap',
        height: 'auto',
        overflow: 'auto',
        paddingBottom: '12px'
      } as React.CSSProperties)
    : ({
        marginLeft: '-17px',
        marginRight: '-17px',
        display: 'flex',
        flexWrap: 'wrap',
        height: 'auto',
        overflow: 'auto',
        paddingBottom: '12px'
      } as React.CSSProperties);

  // dealItems.push(<SignupForm key="signup" />);

  const dealItems = (dealsWithASIN || [])
    .map((props) => {
      return (
        <CardComponent
          {...props}
          key={`${props.title}_${props.ASIN}`}
          toggleOnlyCoupons={toggleOnlyCoupons}
          onlyCoupons={onlyCoupons}
          tag={tagData}
        />
      );
    })
    .slice(0, amount);

  // put <Signup form in the 25th position
  if (dealItems.length >= 24) {
    dealItems.splice(
      24,
      0,
      <Box sx={emailSignUpWrapperStyles} key="signup-hot-deals">
        <SignupForm customPrompt="Sign up to our Hot Deals email list to make sure you don't miss any deals!" />
      </Box>
    );
  }

  // put <Signup form in the 25th position
  if (dealItems.length >= 49) {
    // this is 49 due to the sign up item above
    dealItems.splice(
      49,
      0,
      <Box sx={emailSignUpWrapperStyles} key="signup-daily-deals">
        <SignupForm
          customTitle="Get a round up of deals once a day at 6am PST"
          mailingList={config.sendGrid.mailingLists.dailyDeals}
          customPrompt="Sign up to our Daily Deals email list to get a daily round up of the BEST Amazon deals"
        />
      </Box>
    );
  }

  // uncomment to test the loading state
  // dealItems.unshift(<SkeletonDealCard key="test"/>);

  const renderReceiptDealContainer = () => {
    if (dealSummaryASIN) {
      return (
        <ReceiptDealContainer
          dealSummary={dealSummary}
          dealSummaryASIN={dealSummaryASIN}
          dealsWithASIN={dealsWithASIN}
          handleReceiptDealClose={() => {
            dispatch({
              type: DEALS_UPDATE_RECEIPT_DEAL,
              receiptDeal: null
            });

            history.push('/');
          }}
          tag={tagData}
        />
      );
    }

    return null;
  };

  const SkeletonComponent = shouldShowV2Layout
    ? SkeletonDatabaseCard
    : SkeletonDealCard;

  const renderLoader = () => {
    return (
      <>
        {_.times(dealPageConfig.loadingSkeletons, (i) => (
          <SkeletonComponent key={i} />
        ))}
      </>
    );
  };

  const renderInfiniteDeals = () => {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(128, 128, 128, 0.16)',
          marginLeft: '-16px',
          marginRight: '-17px'
        }}
      >
        <Box margin="10px 16px 10px 17px">
          <InfiniteScroll
            style={infiniteContainerStyles}
            dataLength={dealItems.length} // This is important field to render the next data
            next={() => loadMore()}
            hasMore={
              (dealsWithASIN.length > amount && !latestDealsLoading) ||
              latestDealsLoading
            }
            loader={renderLoader()}
            endMessage={
              <DatabaseEndMessage
                resetAll={resetAll}
                isFiltered={hasFilters()}
              />
            }
          >
            {dealItems}
          </InfiniteScroll>
        </Box>
      </Box>
    );
  };

  const renderSortDeals = () => {
    return (
      <Grid item xs={12} sm={6}>
        <FormControl
          fullWidth
          sx={{
            margin: '10px 4px 0px 4px !important',
            width: 'calc(100% - 8px) !important'
          }}
          size="small"
        >
          <InputLabel id="sort-deals">Sort</InputLabel>
          <DealSortSelect sort={sort} handleSortChange={handleSortChange} />
        </FormControl>
      </Grid>
    );
  };

  return (
    <>
      {renderReceiptDealContainer()}
      <Disclaimer />
      <Box marginLeft="-8px">
        <Grid container>
          {renderSortDeals()}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              alignItems: 'end',
              display: 'flex'
            }}
          >
            <SearchFilterField
              setIsFiltering={setIsFiltering}
              dealsWithASIN={dealsWithASIN}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
            />
            <DatabaseSettings />
          </Grid>
        </Grid>
      </Box>
      <FilterChips
        funcs={{
          ...funcs
        }}
      />
      {isAdmin ? (
        <StrategyChips
          currentlyShowingDeals={dealsWithASIN}
          strategyFilter={strategyFilter}
          setStrategyFilter={setStrategyFilter}
        />
      ) : (
        <BrandChips
          latestDealsLoading={latestDealsLoading}
          setSearchFilter={(brand: string) => {
            setSearchFilter(brand);
          }}
          currentlyShowingDeals={dealsWithASIN}
        />
      )}
      {(isFiltering || hasFilters()) &&
        dealsWithASIN?.length > 0 &&
        unfilteredDealsWithASIN?.length > 0 && (
          <Typography variant="caption">{`Showing ${dealsWithASIN?.length} of ${unfilteredDealsWithASIN?.length} deals...`}</Typography>
        )}
      {(isFiltering || hasFilters()) &&
        (dealsWithASIN || []).length === 0 &&
        (unfilteredDealsWithASIN || []).length === 0 &&
        latestDealsLoading && <Skeleton height="16px" width="166px" />}
      {renderInfiniteDeals()}
      <ScrollTop />
    </>
  );
};

const mapStateToProps = ({
  home: { receiptDeal, dealSummaryASIN, dealSummary }
}: AppState) => ({
  receiptDeal,
  dealSummaryASIN,
  dealSummary
});

export default connect(mapStateToProps)(Deals);
