import React from 'react';
import { List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { format, formatDistance } from 'date-fns';
import { useGetMailingHistoryEnabled } from '@hooks/useSendMailing';
import {
  getMailingListDisplayNameFromId,
  filterOutTestEmailLists
} from '../../utils/mailingLists';

const MailingHistory = ({
  ASIN,
  filterTests = false
}: {
  ASIN: string;
  filterTests?: boolean;
}) => {
  const { data: mailingHistory } = useGetMailingHistoryEnabled({
    ASIN,
    slug: null,
    promoCode: null
  });

  const toRender = filterTests
    ? filterOutTestEmailLists(mailingHistory || [])
    : mailingHistory || [];

  if (toRender.length === 0) {
    return null;
  }

  return (
    <List dense>
      {toRender.map((mailing) => (
        <Tooltip
          key={mailing.id}
          title={format(
            new Date(mailing.createdAt),
            "EEEE, MMMM d, yyyy 'at' h:mm a"
          )}
        >
          <ListItem key={mailing.id}>
            <ListItemText
              primary={getMailingListDisplayNameFromId(mailing.sentToList)}
              secondary={`Sent ${formatDistance(
                new Date(),
                new Date(mailing.createdAt)
              )} ago, opened ${mailing.uniqueOpens} times, clicked ${
                mailing.clicks
              } times`}
            />
          </ListItem>
        </Tooltip>
      ))}
    </List>
  );
};

export default MailingHistory;
