import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { useGetUserData } from '@hooks/useGetUserData';
import { DealPostType } from '@types';
import strategyIds from '@utils/constants/strategyIds';
import ShowIcon from '@components/ShowIcon';

const DealCardActions = (props: DealPostType) => {
  const { strategyId } = props;
  const { data: user } = useGetUserData();

  const isAdmin = !!user?.isAdmin;

  const getStrategyIds = () => {
    if (!strategyId) {
      return [];
    }
    return strategyId.split(',') || [];
  };

  if (!isAdmin) {
    return null;
  }

  const getStrategyIconNames = () => {
    const names = getStrategyIds().map((id) => {
      const strategy = strategyIds.find(
        (strategyId) => strategyId.value === id
      );
      return {
        icon: strategy?.icon,
        label: strategy?.label
      };
    });

    return names.filter((iconName) => !!iconName.icon);
  };

  const strategyIcons = getStrategyIconNames();

  return (
    <>
      {strategyIcons && strategyIcons.length > 0 && (
        <Box>
          {strategyIcons.map((iconName) => {
            return (
              <Tooltip
                key={iconName.label}
                title={iconName.label}
                placement="top"
              >
                <span>
                  <ShowIcon icon={iconName.icon} />
                </span>
              </Tooltip>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default DealCardActions;
