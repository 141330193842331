/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios, { AxiosInstance } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import config from '@configFile';
import { DealPostType } from '../types';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.API_PREFIX
});

const queryBrandDeals = async (brand: string): Promise<DealPostType[]> => {
  const result = await axiosInstance.get(`/api/get-brand-deals/${brand}`);
  return result.data;
};

export function useGetBrandDeals(
  brand: string
): UseQueryResult<DealPostType[]> {
  return useQuery<DealPostType[], Error>(
    `brand-deals-${brand}`,
    () => queryBrandDeals(brand),
    {
      onError: () => {
        console.error('Something went wrong while fetching the brand deals');
      }
    }
  );
}
