import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// import Avatar from '@mui/material/Avatar';
// import { useTheme } from '@mui/system';
import { DealPostType } from '@types';
import strategyIds from '@utils/constants/strategyIds';
import ShowIcon from '@components/ShowIcon';

type FrequencyResult = {
  value: string;
  total: number;
};

const StrategyChips = ({
  currentlyShowingDeals,
  strategyFilter,
  setStrategyFilter
}: {
  currentlyShowingDeals: DealPostType[];
  strategyFilter: string;
  setStrategyFilter: (value: string) => void;
}) => {
  const getStrategyLabelByValue = (value: string) => {
    const strategy = strategyIds.find((id) => id.value === value);
    return strategy ? strategy.label : value;
  };

  const getStrategyObjByValue = (value: string) => {
    return strategyIds.find((id) => id.value === value);
  };

  const getStrategyIdFrequency = (data: DealPostType[]): FrequencyResult[] => {
    const frequencyMap: Record<string, number> = {};
    for (let i = 0; i < data.length; i++) {
      const { strategyId } = data[i];
      if (strategyId && strategyId.trim() !== '') {
        const ids = strategyId.split(',').map((id) => id.trim());
        for (let j = 0; j < ids.length; j++) {
          frequencyMap[ids[j]] = (frequencyMap[ids[j]] || 0) + 1;
        }
      }
    }

    // Convert the frequency map to an array of objects
    const frequencyArray: FrequencyResult[] = Object.entries(frequencyMap)
      .map(([value, total]) => ({ value, total }))
      .sort((a, b) => b.total - a.total); // Sort by frequency in descending order

    return frequencyArray;
  };

  //   const theme = useTheme();
  //   const isDarkMode = theme.palette.mode === 'dark';
  // brands is string[] when loaded, undefined otherwise
  //   const { data: brands, isLoading } = useGetBrandFilters();
  //   const loading = latestDealsLoading || isLoading;
  const loading = false;
  const loadingChipStyles = {
    borderRadius: '16px',
    marginRight: '6px',
    display: 'inline-block',
    flexShrink: 0
  };

  const isChipSelected = (strategyId: string) => {
    return strategyFilter.includes(strategyId);
  };

  const freq = getStrategyIdFrequency(currentlyShowingDeals);

  const displayStrategyIds = freq.filter((f) =>
    strategyIds.find((id) => id.value === f.value)
  );

  // organize displayStrategyIds by is selected
  displayStrategyIds.sort((a, b) => {
    const aIsSelected = isChipSelected(a.value);
    const bIsSelected = isChipSelected(b.value);
    if (aIsSelected && !bIsSelected) {
      return -1;
    }
    if (!aIsSelected && bIsSelected) {
      return 1;
    }
    return 0;
  });

  if (currentlyShowingDeals.length < 3 && displayStrategyIds.length < 5) {
    return null;
  }

  return (
    <Box
      sx={{
        overflowX: 'auto', // Enable horizontal scrolling
        display: 'flex', // Flex to align the chips
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
        '&::-webkit-scrollbar': {
          display: 'none' // Hide scrollbar in Chrome
        },
        whiteSpace: 'nowrap', // Prevent chips from wrapping
        marginRight: {
          xs: '-17px',
          md: 'initial'
        },
        marginLeft: {
          xs: '-10px',
          md: 'initial'
        },
        marginTop: '8px'
      }}
    >
      {loading ? (
        // Show multiple loading skeleton chips for better visual effect
        <>
          <Skeleton
            variant="rectangular"
            width="80px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="50px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="65px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="70px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="80px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="90px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="70px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="75px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="85px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="50px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="65px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="70px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="80px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="55px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="85px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="50px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="65px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="70px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="80px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="90px"
            height="32px"
            sx={loadingChipStyles}
          />
        </>
      ) : (
        displayStrategyIds?.map((displayStrategyId) => (
          <Chip
            avatar={
              <ShowIcon
                icon={getStrategyObjByValue(displayStrategyId.value).icon}
              />
            }
            key={displayStrategyId.value}
            label={getStrategyLabelByValue(displayStrategyId.value)}
            sx={{ marginRight: '6px' }}
            onClick={() => {
              if (isChipSelected(displayStrategyId.value)) {
                setStrategyFilter(
                  strategyFilter.replace(displayStrategyId.value, '').trim()
                );
              } else {
                setStrategyFilter(
                  `${strategyFilter} ${displayStrategyId.value}`.trim()
                );
              }
            }}
            variant={
              isChipSelected(displayStrategyId.value) ? 'filled' : 'outlined'
            }
            onDelete={
              !isChipSelected(displayStrategyId.value)
                ? undefined
                : () => {
                    setStrategyFilter(
                      strategyFilter.replace(displayStrategyId.value, '').trim()
                    );
                  }
            }
          />
        ))
      )}
    </Box>
  );
};

export default StrategyChips;
