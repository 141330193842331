import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { useGetBrandDeals } from '@hooks/useGetBrandDeals';
import SummaryListDeal from '../SummaryListDeal';
import colors from '../../../../utils/colors';

const BrandDeals = ({
  currentASIN,
  brand
}: {
  currentASIN: string;
  brand: string;
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { data: deals, isLoading } = useGetBrandDeals(brand);

  if (isLoading || !deals) {
    return null;
  }

  const filteredDeals = (deals || []).filter(
    (deal: any) => deal.ASIN !== currentASIN
  );

  if (!deals || filteredDeals.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: 8
      }}
    >
      <Accordion
        defaultExpanded
        sx={{
          border: `1px solid ${colors.brand}`,
          borderRadius: '4px',
          background: colors.brand
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: 'white'
              }}
            />
          }
          sx={{
            background: colors.brand,
            borderRadius: '4px'
          }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold'
            }}
            variant="subtitle2"
          >
            More deals from {brand}
            {filteredDeals.length > 0
              ? ` (${filteredDeals.length} deal${
                  filteredDeals.length > 1 ? 's' : ''
                })`
              : ''}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: isDarkMode
              ? theme.palette.background.paper
              : 'white'
          }}
        >
          {(filteredDeals || []).map((deal) => (
            <SummaryListDeal
              key={deal.ASIN}
              deal={deal}
              tag={config.AFFILIATE_TAGS.JD_BRAND}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BrandDeals;
