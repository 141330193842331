import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import trackUse from '@utils/trackUse';
import { formatPrice, logPostHogEvent } from '@utils/index';
import { DealPostType } from '@types';

export default function CompareTo({
  deal,
  styles
}: {
  deal: DealPostType;
  styles?: React.CSSProperties;
}) {
  if (!deal?.compareImage) {
    return null;
  }

  return (
    <ImageList sx={styles} cols={1}>
      <ImageListItem key={deal.compareImage}>
        <img
          srcSet={`${deal.compareImage}`}
          src={`${deal.compareImage}`}
          alt={deal.title}
          loading="lazy"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '300px'
          }}
        />
        <ImageListItemBar
          title={`Compare to ${formatPrice(deal.comparePrice)} @ ${
            deal.compareTo
          }`}
          // subtitle={'empty'}
          actionIcon={
            <IconButton
              sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
              aria-label={`Image of ${
                deal.title
              } being priced for ${formatPrice(deal.comparePrice)} @ ${
                deal.compareTo
              }`}
              onClick={() => {
                // open new window to image url
                window?.open(deal.compareImage, '_blank');
                logPostHogEvent('compare-to-click', {
                  value: `${deal.compareImage} - ${deal.ASIN}`,
                  type: 'CLICK'
                });
                trackUse({
                  item: `compare-to-click`,
                  value: `${deal.compareImage} - ${deal.ASIN}`,
                  type: 'CLICK'
                });
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          }
        />
      </ImageListItem>
    </ImageList>
  );
}
