/* eslint-disable jsx-a11y/anchor-is-valid, @typescript-eslint/no-unused-vars, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { ReactNode } from 'react';
import {
  ListItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/system';
import ShowIcon from '@components/ShowIcon';
import { defaultUISettings } from '@hooks/useUserSettings';
import { logPostHogEvent } from '@utils/index';
import trackUse from '@utils/trackUse';
import { SettingItemProps } from '../../utils';

const SettingItem: React.FC<SettingItemProps> = ({
  setting,
  user,
  userSettings,
  updateUserSettings,
  showNonLoggedInModal
}) => {
  const theme = useTheme();
  const settingCurrentValue =
    user && userSettings
      ? userSettings[setting.internalName]
      : defaultUISettings[setting.internalName];

  const handleSettingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!user) {
      showNonLoggedInModal({
        onLoginCBFn: () => {
          trackUse({
            item: 'non-loggedin-setting',
            value: setting.internalName,
            type: 'CLICK'
          });
          updateUserSettings({ [setting.internalName]: e.target.value });
          logPostHogEvent('non-loggedin-setting', {
            value: setting.internalName,
            type: 'CLICK'
          });
        },
        featureName: `setting-${setting.internalName}`
      });
      return;
    }

    trackUse({
      item: 'loggedin-setting',
      value: setting.internalName,
      type: 'CLICK'
    });
    updateUserSettings({ [setting.internalName]: e.target.value });
  };

  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <ListItem dense sx={{ marginTop: '24px' }}>
      <FormControl>
        <Box display="flex">
          <ShowIcon
            icon={setting.icon}
            sx={{
              marginRight: '10px !important',
              color: isDarkMode ? '#FFF !important' : '#00000099 !important',
              width: '20px !important',
              height: '20px !important',
              marginBottom: '4px !important'
            }}
          />
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{
              color: `${theme.palette.primary.text} !important`,
              fontWeight: 700,
              fontSize: '18px !important'
            }}
          >
            {setting.name}
          </FormLabel>
        </Box>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            marginBottom: '4px !important',
            marginTop: '12px !important'
          }}
          color="text.secondary"
        >
          {setting.explanation}
        </Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={settingCurrentValue}
          name="radio-buttons-group"
          onChange={handleSettingChange}
        >
          {setting.options.map((option) => (
            <FormControlLabel
              key={option.name}
              value={option.value}
              control={<Radio />}
              sx={{ marginTop: option.explanation ? '8px' : '0px' }}
              disabled={option.isUserOnly && !user}
              label={
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: `${theme.palette.primary.text} !important`,
                      fontWeight: 700
                    }}
                  >
                    {option.name}
                    {option.isUserOnly && !user ? ' (users only)' : ''}
                  </Typography>
                  {option.explanation && (
                    <Typography variant="body2" color="text.secondary">
                      {option.explanation}
                    </Typography>
                  )}
                </Box>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </ListItem>
  );
};

export default SettingItem;
