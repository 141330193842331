import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGiftCards } from '@hooks/useGiftCards';
import config from '@configFile';
import colors from '../../../../utils/colors';
import GiftCardDeals from '../../../GiftCardDeals';

const GiftCards = ({
  tag = config.AFFILIATE_TAGS.PRODUCT_PAGE
}: {
  tag?: string;
}) => {
  const { data: giftCardDeals } = useGiftCards();

  if (!giftCardDeals || giftCardDeals.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: 8
      }}
    >
      <Accordion
        defaultExpanded
        sx={{
          border: `1px solid ${colors.giftCards}`,
          borderRadius: '4px',
          background: colors.giftCards
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: 'white'
              }}
            />
          }
          sx={{
            background: colors.giftCards,
            borderRadius: '4px'
          }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold'
            }}
            variant="subtitle2"
          >
            Gift Card Deals
            {giftCardDeals.length > 0
              ? ` (${giftCardDeals.length} deal${
                  giftCardDeals.length > 1 ? 's' : ''
                })`
              : ''}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: 'white'
          }}
        >
          <GiftCardDeals tag={tag} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GiftCards;
