/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios, { AxiosInstance } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import config from '@configFile';
import { DealPostType } from '../types';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.API_PREFIX
});

const queryDealListDeals = async (listId: number): Promise<DealPostType[]> => {
  const result = await axiosInstance.get(`/api/get-deal-list-deals/${listId}`);
  return result.data;
};

export function useGetDealListDeals(
  listId: number
): UseQueryResult<DealPostType[]> {
  return useQuery<DealPostType[], Error>(
    `deal-list-deals-${listId}`,
    () => queryDealListDeals(listId),
    {
      onError: () => {
        console.error(
          'Something went wrong while fetching the list item deals'
        );
      }
    }
  );
}
