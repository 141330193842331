import { DealFiltersType, DealPostType } from '@types';
import { getCurrentDealEventStrObj } from '@utils/index';

export const filterByCategory = (
  categoryFilters: string[],
  transformedDeals: DealPostType[]
) => {
  if ((categoryFilters || []).length > 0) {
    // eslint-disable-next-line no-param-reassign
    transformedDeals = transformedDeals.filter((deal) => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < categoryFilters.length; i++) {
        if (
          deal.category &&
          deal.category
            .toLowerCase()
            .includes(categoryFilters[i]?.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
  }

  return transformedDeals;
};

export const filterLatestDeals = (
  dealFilters: DealFiltersType,
  latestDeals: DealPostType[],
  favoriteItems: string[],
  searchFilter: string
) => {
  let transformedDeals = [...latestDeals];
  const {
    onlyFavorites,
    onlySponsored,
    categoryFilters,
    onlyCoupons,
    onlySubscribeSave,
    onlyShowNew,
    trending,
    primeDay,
    strategyFilter
  } = dealFilters;

  if (onlySponsored) {
    transformedDeals = transformedDeals.filter((deal) => !!deal.s);
  }

  if (onlyFavorites) {
    transformedDeals = transformedDeals.filter((deal) =>
      favoriteItems.includes(deal.ASIN)
    );
  }

  if (onlyShowNew) {
    transformedDeals = transformedDeals.filter((deal) => {
      if (!deal || !deal?.postDate) {
        return false;
      }
      // if deal.postDate in the last 24 hours then return true
      const now = new Date();
      const postDate = new Date(deal.postDate);
      const diff = now.getTime() - postDate.getTime();
      const diffHours = diff / (1000 * 3600);
      return diffHours < 24;
    });
  }

  transformedDeals = filterByCategory(categoryFilters, transformedDeals);

  if (onlyCoupons) {
    transformedDeals = transformedDeals.filter(
      (deal) => deal.couponFixed || deal.couponPercentage
    );
  }

  if (onlySubscribeSave) {
    transformedDeals = transformedDeals.filter((deal) => !!deal.ss);
  }

  if (trending) {
    transformedDeals = transformedDeals.filter((deal) => !!deal.isBestSeller);
  }

  const currentEventObj = getCurrentDealEventStrObj();

  if (primeDay && currentEventObj) {
    transformedDeals = transformedDeals.filter((deal) => {
      return !!(deal?.listingMode || '')
        .toLowerCase()
        .includes(currentEventObj?.value?.toLowerCase());
    });
  }

  if (searchFilter !== '') {
    transformedDeals = transformedDeals.filter((deal) => {
      // also search the brand
      return (
        deal.title.toLowerCase().includes(searchFilter.toLowerCase()) ||
        (deal.brand || '').toLowerCase().includes(searchFilter.toLowerCase()) ||
        deal.ASIN.toLowerCase().includes(searchFilter.toLowerCase())
      );
    });
  }

  if (strategyFilter) {
    const spaceSepValues = strategyFilter.split(' ');
    transformedDeals = transformedDeals.filter((deal) => {
      return spaceSepValues.some((val) => {
        return deal?.strategyId?.toLowerCase().includes(val.toLowerCase());
      });
    });
  }

  return transformedDeals;
};

export const getNextDealASIN = (
  currentASIN: string,
  dealsWithASIN: DealPostType[]
) => {
  const index = (dealsWithASIN || []).findIndex((d) => d.ASIN === currentASIN);
  if (index === -1 || index === dealsWithASIN.length - 1) {
    return null;
  }
  return dealsWithASIN[index + 1]?.ASIN || null;
};

export const getPreviousDealASIN = (
  currentASIN: string,
  dealsWithASIN: DealPostType[]
) => {
  const index = (dealsWithASIN || []).findIndex((d) => d.ASIN === currentASIN);
  if (index === -1 || index === 0) {
    return null;
  }
  return dealsWithASIN[index - 1]?.ASIN || null;
};
