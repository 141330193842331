/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Box, Chip, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import trackUse from '@utils/trackUse';
import { useSignUpModal } from '@hooks/useLoginModal';
import { logPostHogEvent, getCurrentDealEventStrObj } from '@utils/index';

import ShowIcon from '@components/ShowIcon';
import { useGetUserData } from '@hooks/useGetUserData';
import MainFilterChip from './components/MainFilterChip';
import { FilterFunctionsType, FilterChipConfigType } from './utils';

const FilterChips = ({
  funcs: {
    trending,
    setTrending,
    primeDay,
    setPrimeDay,
    onlyCoupons,
    setOnlyCoupons,
    onlySubscribeSave,
    setOnlySubscribeSave,
    onlyShowNew,
    setOnlyShowNew,
    setCategoryFilters,
    categoryFilters,
    onlyFavorites,
    setOnlyFavorites,
    onlySponsored,
    setOnlySponsored,
    resetAll
  }
}: {
  funcs: FilterFunctionsType;
}) => {
  const { data: user, isLoading } = useGetUserData();
  const { showNonLoggedInModal } = useSignUpModal();
  const isAdmin = !!user?.isAdmin;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  // trigger a render after everything has finished loading
  // this is to attempt to fix the chip background bug
  useEffect(() => {
    if (!isLoading) {
      setOnlyFavorites(!!onlyFavorites);
    }
  }, [isLoading]);

  let filterChipConfig: FilterChipConfigType = [];

  const eventObj = getCurrentDealEventStrObj();

  if (eventObj && eventObj?.shouldShowDeals) {
    const {
      label,
      overrideChipLabel,
      overrideChipColor,
      overrideChipSelectedColor,
      overrideChipColorDarkMode,
      overrideChipSelectedColorDarkMode
    } = eventObj;

    const chipColor =
      (isDarkMode ? overrideChipColorDarkMode : overrideChipColor) ||
      eventObj.dealHighlightColor;
    const chipSelectedColor =
      (isDarkMode
        ? overrideChipSelectedColorDarkMode
        : overrideChipSelectedColor) ||
      eventObj.chipSelectedColor ||
      '#f50095';

    filterChipConfig.push({
      index: 0,
      label: overrideChipLabel || label,
      value: 'eventDay',
      icon: eventObj?.iconName || 'CelebrationIcon',
      iconColor: chipColor,
      // iconColor: !isEarlySale
      //   ? eventObj.dealHighlightColor
      //   : colors.earlyEventDeal,
      // selectedColor: !isEarlySale ? eventObj.chipSelectedColor : '#f50095',
      selectedColor: chipSelectedColor,

      selected: primeDay,
      onClick: () => setPrimeDay(!primeDay),
      isMultifilter: false
    });
  }

  filterChipConfig = filterChipConfig.concat([
    {
      index: 0,
      label: 'Popular',
      value: 'popular',
      icon: 'InsightsIcon',
      iconColor: '#C25700',
      selected: !!trending,
      onClick: () => setTrending(!trending),
      isMultifilter: false
    },
    {
      index: 1,
      label: 'Coupon',
      value: 'coupon',
      icon: 'LocalOfferIcon',
      iconColor: theme.palette.couponColor,
      selected: !!onlyCoupons,
      onClick: () => setOnlyCoupons(!onlyCoupons),
      isMultifilter: false
    },
    {
      index: 2,
      label: 'Subscribe & Save',
      value: 'subscribeSave',
      icon: 'AutorenewIcon',
      iconColor: '#00796b',
      selected: onlySubscribeSave,
      onClick: () => setOnlySubscribeSave(!onlySubscribeSave),
      isMultifilter: false
    },
    {
      index: 3,
      label: 'New',
      value: 'new',
      icon: 'NewReleasesIcon',
      iconColor: theme.palette.newColor,
      selected: onlyShowNew,
      onClick: () => setOnlyShowNew(!onlyShowNew),
      isMultifilter: false
    }
  ]);

  if (user) {
    filterChipConfig.push({
      index: 4,
      label: 'Favorites',
      value: 'favorites',
      icon: 'HeartIcon',
      iconColor: theme.palette.favoriteColor,
      selected: onlyFavorites,
      onClick: () => setOnlyFavorites(!onlyFavorites),
      isMultifilter: false
    });
  } else {
    filterChipConfig.push({
      index: 4,
      label: 'Favorites',
      value: 'favorites',
      icon: 'HeartIcon',
      iconColor: theme.palette.favoriteColor,
      selected: false,
      onClick: () => {
        showNonLoggedInModal({
          onLoginCBFn: () => {
            setOnlyFavorites(!onlyFavorites);
          },
          modalMessage: 'Please login for us to locate your favorites',
          featureName: 'chip-see-favorites-login-modal'
        });
      },
      isMultifilter: false
    });
  }

  if (isAdmin) {
    filterChipConfig.push({
      index: 5,
      label: 'Sponsored',
      value: 'admin',
      icon: 'PaidIcon',
      iconColor: '#388e3c',
      selected: onlySponsored,
      onClick: () => setOnlySponsored(!onlySponsored),
      isMultifilter: false
    });
  }

  const categoryOptions = config.CATEGORY_FILTER.filter(
    (cat) => !cat?.skipDatabase
  ).map((category, index) => ({
    index: index + filterChipConfig.length,
    label: category.shortName,
    value: category.value,
    icon: category.icon,
    iconColor: 'inherit',
    isMultifilter: true,
    selected: categoryFilters.includes(category.value),
    onClick: () => {
      const newCategoryFilters = [...categoryFilters];
      if (newCategoryFilters.includes(category.value)) {
        newCategoryFilters.splice(
          newCategoryFilters.indexOf(category.value),
          1
        );
      } else {
        newCategoryFilters.push(category.value);
      }
      setCategoryFilters(newCategoryFilters);
    }
  }));

  const selectedCountMainFilterCount = filterChipConfig.filter(
    (filter) => filter.selected
  ).length;
  const selectedCategoryCount = categoryOptions.filter(
    (filter) => filter.selected
  ).length;
  const selectedCount = selectedCountMainFilterCount + selectedCategoryCount;
  const selectedString = selectedCount > 0 ? ` (${selectedCount})` : '';

  return (
    <Box
      sx={{
        margin: '0px auto 10px auto'
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="overline">
          {`Filter by ${selectedString}`}
        </Typography>
        <Box>
          <Box>
            <Link
              variant="overline"
              onClick={() => {
                resetAll();
              }}
              sx={{
                cursor: 'pointer',
                color: isDarkMode ? theme.palette.linkColor : '#392e50',
                fontWeight: 700,
                fontSize: '0.75rem'
              }}
              href="#"
            >
              Clear all filters
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          // make this scrollable to the left and right
          overflowX: 'auto',
          display: 'flex',
          // do not show scroll bar
          scrollbarWidth: 'none',
          // Firefox
          msOverflowStyle: 'none',
          // Chrome
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          marginRight: {
            xs: '-17px',
            md: 'initial'
          },
          marginLeft: {
            xs: '-10px',
            md: 'initial'
          }
        }}
      >
        {(filterChipConfig || []).map((filter) => {
          return <MainFilterChip filter={filter} key={filter.label} />;
        })}

        {(categoryOptions || []).map((filter) => {
          const color = filter.selected ? 'success' : 'default';

          const logClick = () => {
            logPostHogEvent('filter-chip', {
              value: `${filter.label} - ${
                filter.selected ? 'DESELECT' : 'SELECT'
              }`,
              type: 'ACTION'
            });
            trackUse({
              item: `filter-chip`,
              value: `${filter.label} - ${
                filter.selected ? 'DESELECT' : 'SELECT'
              }`,
              type: 'ACTION'
            });
            filter.onClick();
          };

          return (
            <Chip
              color={color}
              key={filter.label}
              label={filter.label}
              variant="outlined"
              icon={<ShowIcon icon={filter.icon} />}
              onClick={logClick}
              sx={{
                marginRight: '6px'
              }}
              onDelete={filter.selected ? filter.onClick : undefined}
            />
          );
        })}
      </Box>
    </Box>
  );
};
export default FilterChips;
