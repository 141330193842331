import React from 'react';
import { Typography, Box } from '@mui/material';
import config from '@configFile';
import { useGetSpecialOffers } from '@hooks/useSpecialOffers';
import { addAffiliateTagToLink } from '@utils/index';
import Loading from '@components/Loading';
import CurrentSpecialOfferCard from './components/CurrentSpecialOfferCard';

const CurrentAmazonSpecialOffers = ({
  showTitle = false,
  small = false
}: {
  showTitle?: boolean;
  small?: boolean;
}) => {
  const tag = config.AFFILIATE_TAGS.JD_SPECIAL_OFFERS;
  const { data: specialOffers, isLoading } = useGetSpecialOffers();

  if (!isLoading && specialOffers?.length === 0) {
    return null;
  }

  return (
    <Box>
      {showTitle ? (
        <Typography
          variant="h5"
          component="div"
          sx={{
            marginBottom: small ? '8px' : '1.5rem'
          }}
          gutterBottom
        >
          Current Amazon Special Offers
        </Typography>
      ) : null}
      {isLoading ? <Loading /> : null}
      {(specialOffers || []).map((so) => {
        const link = addAffiliateTagToLink(so.link, tag);
        const isLastOffer =
          specialOffers.indexOf(so) === specialOffers.length - 1;
        return (
          <CurrentSpecialOfferCard
            so={so}
            isLastOffer={isLastOffer}
            small={small}
            tag={tag}
            key={link}
          />
        );
      })}
    </Box>
  );
};

export default CurrentAmazonSpecialOffers;
