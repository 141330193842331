/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { getHelmetMeta, getSEOSchema } from '@utils/seo';
import PageContainer from '@components/PageContainer';
import { getCurrentDealEventStrObj, getPostPrefix } from '@utils/index';
import config from '@configFile';
import { AppState } from '@types';
import Deals from './Deals';
// eslint-disable-next-line import/no-named-as-default

interface DealsContainerProps extends ReturnType<typeof mapStateToProps> {
  isEventDay?: boolean;
}

const DealsContainer = (props: DealsContainerProps) => {
  const { dealPageTitle, ogPath, ogImage, ogTitle, isEventDay, slug } = props;
  const event = getCurrentDealEventStrObj();
  const ogTitleWithPrefix = `${getPostPrefix()}${ogTitle}`;
  const image =
    ogImage || 'https://www.jungledealsblog.com/jd-images/page-deals/3.jpg';

  const pageTitle =
    isEventDay || !!event?.showSitePrefix
      ? 'Hand-Picked Prime Deals'
      : `${getPostPrefix()}${dealPageTitle}`;

  const description =
    'Discover the best Amazon deals with our comprehensive list of name-brand products featuring exclusive coupons and special offers. Save big on your favorite items with our daily updated discounts.';

  return (
    <PageContainer title={pageTitle} fullWidth isAdmin={false}>
      <Helmet
        title={ogTitleWithPrefix}
        meta={getHelmetMeta({
          title: ogTitleWithPrefix,
          image,
          description,
          url: ogPath
        })}
        script={[
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(
              getSEOSchema({
                title: ogTitleWithPrefix,
                image: [ogImage],
                description
              })
            )
          }
        ]}
        // add canonical link to the page
        link={
          slug
            ? [
                {
                  rel: 'canonical',
                  href: `${config.SITE_URL}/blog/${slug}`
                }
              ]
            : [
                {
                  rel: 'canonical',
                  href: `${config.SITE_URL}`
                }
              ]
        }
      >
        <meta name="description" content={config.DESCRIPTION} />
      </Helmet>
      <Deals isEventDay={isEventDay} />
    </PageContainer>
  );
};

const mapStateToProps = ({
  home: { dealPageTitle, ogPath, ogTitle, ogImage, slug }
}: AppState) => ({
  dealPageTitle,
  ogPath,
  ogTitle,
  ogImage,
  slug
});

export default connect(mapStateToProps)(DealsContainer);
