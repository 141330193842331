/* eslint-disable jsx-a11y/anchor-is-valid, @typescript-eslint/no-unused-vars, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { MouseEvent } from 'react';
import {
  IconButton,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import {
  useGetUserSettings,
  useUpdateUserSettings
} from '@hooks/useUserSettings';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSignUpModal } from '@hooks/useLoginModal';
import trackUse from '@utils/trackUse';
import FeedbackButton from '@components/FeedbackButton';
import { Setting } from './utils';
import SettingItem from './components/SettingItem';

const settings: Setting[] = [
  {
    name: 'Percentage Discount Basis',
    internalName: 'databasePercentageBasis',
    enabled: true,
    options: [
      {
        name: 'List price',
        value: 'LIST_PRICE',
        explanation: `Percentage discount will be calculated off of the list price Amazon provides.`,
        isUserOnly: false
      },
      {
        name: 'Current price before additional discounts',
        isUserOnly: true,
        value: 'BEFORE_PRICE',
        explanation: `Percentage discount will be calculated off of current price before any discounts we've found are applied.`
      }
    ],
    icon: 'PercentIcon',
    explanation: `How should percentage discount be calculated? Amazon may provide us some wild list prices we are obligated to show, so we can calculate percentage discount off of the price before discounts.`
  },
  {
    name: 'Sticky Sort & Filters',
    internalName: 'databaseStickyFilters',
    enabled: true,
    options: [
      {
        name: 'Disable sticky sort & filters',
        value: false,
        explanation:
          'Sort setting & filters will reset when you navigate away from the page.'
      },
      {
        name: 'Enable sticky sort & filters',
        isUserOnly: true,
        value: true,
        explanation:
          'Sort setting & filters will remain when you navigate away from the page.'
      }
    ],
    icon: 'FilterAltIcon',
    explanation: `This determines whether or not we remember your filters when you navigate away from the page. This does not apply to search terms.`
  },
  {
    name: 'Show Ribbons',
    internalName: 'databaseShowRibbons',
    icon: 'BookIcon',
    enabled: false,
    options: [
      {
        name: 'Show deal ribbons',
        value: true
      },
      {
        name: 'Hide deal ribbons',
        isUserOnly: true,
        value: false
      }
    ],
    explanation: `This determines whether or not we show ribbons on deals.`
  }
];

const DatabaseSettings: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const { data: user } = useGetUserData();
  const { data: userSettings } = useGetUserSettings();
  const { mutate: updateUserSettings } = useUpdateUserSettings();
  const { showNonLoggedInModal } = useSignUpModal();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    trackUse({ item: 'browsing-settings', value: 'open', type: 'CLICK' });
  };

  const handleClose = () => {
    setAnchorEl(null);
    trackUse({ item: 'browsing-settings', value: 'close', type: 'CLICK' });
  };

  const enabledSettings = settings.filter((setting) => setting.enabled);

  return (
    <Box
      display="inline-flex"
      sx={{ '@media (max-width: 600px)': { marginBottom: '4px' } }}
    >
      <IconButton
        aria-controls={isOpen ? 'long-menu' : undefined}
        aria-label="browsing settings"
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup="true"
        id="browsing-settings-button"
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">User Settings</DialogTitle>
        <DialogContent>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {enabledSettings.map((setting, i) => (
              <SettingItem
                key={setting.internalName}
                setting={setting}
                user={user}
                userSettings={userSettings}
                updateUserSettings={updateUserSettings}
                showNonLoggedInModal={showNonLoggedInModal}
              />
            ))}
          </List>
          <FeedbackButton
            prompt={`If you have any ideas for settings you'd like to see, please send us your feedback.`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DatabaseSettings;
