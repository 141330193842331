/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';

import { useHistory } from 'react-router';
import { useTheme } from '@mui/system';
import config from '@configFile';
import trackUse from '@utils/trackUse';
import { DealPostType } from '@types';
import AdminActions from '@components/AdminActions';

import ReportInaccurate from '@components/ReportInaccurate';
import DealSummary from '@components/DealSummary';

import FavoriteButton from '@components/FavoriteButton';
import Ribbon from '@components/Ribbon';

import { getProductLink, isShareAPISupported, shareUrl } from '@utils/index';
import CouponButton from '@components/CouponButton';
import { iconButtonHoverRaiseStyle } from '@utils/styles';
import { getNextDealASIN, getPreviousDealASIN } from '@pages/Deals/utils';
import { dealsAction } from '@actions/index';
import NavButton from '../NavButton';

const ReceiptDealContainer = ({
  dealSummary,
  dealSummaryASIN,
  dealsWithASIN,
  handleReceiptDealClose,
  tag
}: {
  dealSummary: DealPostType;
  dealSummaryASIN: string;
  dealsWithASIN: DealPostType[];
  handleReceiptDealClose: () => void;
  tag: string;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const seeOnAmazon = (e: React.MouseEvent) => {
    e.stopPropagation();
    const URL = getProductLink(
      dealSummary?.ASIN || '',
      config.AFFILIATE_TAGS.PRODUCT_PAGE,
      dealSummary?.sLink || ''
    );
    trackUse({
      item: 'see-on-amazon-deal-summary',
      value: URL,
      type: 'CLICK'
    });
    window.open(URL, '_blank');
  };

  const goToNextDeal = () => {
    const nextASIN = getNextDealASIN(dealSummaryASIN, dealsWithASIN);
    const URL = `/deal/${nextASIN}`;
    history.push(URL);
    dispatch(dealsAction.getDealSummary(nextASIN));
    trackUse({
      item: 'next-deal-click',
      value: URL,
      type: 'CLICK'
    });
  };

  const goToPrevDeal = () => {
    const prevASIN = getPreviousDealASIN(dealSummaryASIN, dealsWithASIN);
    const URL = `/deal/${prevASIN}`;
    history.push(URL);
    dispatch(dealsAction.getDealSummary(prevASIN));
    trackUse({
      item: 'prev-deal-click',
      value: URL,
      type: 'CLICK'
    });
  };

  const isDarkMode = theme.palette.mode === 'dark';

  const additionalStyles = isDarkMode
    ? {
        backgroundImage: 'none'
      }
    : {};

  return (
    <>
      <Dialog
        open
        onClose={handleReceiptDealClose}
        fullWidth
        maxWidth="md"
        sx={{
          overflow: 'visible !important',
          // backgroundColor: isDarkMode ? '#000' : theme.palette.background.paper,
          // ...additionalStyles,
          '& .MuiDialog-paper': {
            overflow: 'visible',
            '@media (max-width: 540px)': {
              width: 'calc(100% - 8px)',
              margin: '8px'
            }
          }
        }}
        PaperProps={{
          sx: {
            backgroundColor: isDarkMode
              ? '#212121'
              : theme.palette.background.paper,
            ...additionalStyles
          }
        }}
      >
        {getPreviousDealASIN(dealSummaryASIN, dealsWithASIN) && (
          <NavButton
            disabled={
              !dealSummary ||
              !getPreviousDealASIN(dealSummaryASIN, dealsWithASIN)
            }
            onClick={goToPrevDeal}
            isPrevious
          />
        )}
        {getNextDealASIN(dealSummaryASIN, dealsWithASIN) && (
          <NavButton
            disabled={!dealSummary}
            onClick={goToNextDeal}
            isPrevious={false}
          />
        )}
        <Box
          sx={{
            position: 'relative'
          }}
        >
          <Ribbon dealSummary={dealSummary} />
        </Box>
        <DialogActions>
          {dealSummary?.ASIN && (
            <AdminActions ASIN={dealSummary.ASIN} deal={dealSummary} />
          )}
          {isShareAPISupported && (
            <IconButton
              key="btn-share"
              aria-label="Share deal"
              size="small"
              onClick={() => {
                shareUrl();
              }}
              sx={iconButtonHoverRaiseStyle}
            >
              <ShareIcon />
            </IconButton>
          )}
          <IconButton aria-label="Close" onClick={handleReceiptDealClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ paddingTop: '0px' }}>
          <DealSummary />
        </DialogContent>
        <DialogActions>
          {dealSummary?.promoCode ? (
            <CouponButton
              ASIN={dealSummary?.ASIN}
              promoCode={dealSummary?.promoCode}
              tag={tag}
            />
          ) : null}
          {dealSummary?.ASIN ? (
            <ReportInaccurate ASIN={dealSummary.ASIN} />
          ) : null}
          {dealSummary?.ASIN && dealSummary?.finalPrice && (
            <FavoriteButton
              ASIN={dealSummary?.ASIN}
              finalPrice={dealSummary?.finalPrice}
            />
          )}
          {isShareAPISupported && (
            <IconButton
              key="btn-share"
              aria-label="Share deal"
              size="small"
              onClick={() => {
                shareUrl();
              }}
              sx={iconButtonHoverRaiseStyle}
            >
              <ShareIcon />
            </IconButton>
          )}
          <Button
            key="btn-amzon-link"
            size="small"
            sx={{
              padding: '4px 10px !important'
            }}
            startIcon={<LaunchIcon />}
            disabled={!dealSummary}
            onClick={seeOnAmazon}
            variant="outlined"
          >
            Amazon
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return null;
};

export default ReceiptDealContainer;
