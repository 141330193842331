import React from 'react';
import { Box, Grid } from '@mui/material';
import { useGiftCards } from '@hooks/useGiftCards';
import { trackLinkClick, getProductLink } from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import config from '@configFile';

export default ({ tag = config.AFFILIATE_TAGS.JDB_GIFT }: { tag?: string }) => {
  const { data: giftCards } = useGiftCards();
  return (
    <>
      {giftCards && (
        <Grid
          container
          spacing={2}
          style={{
            marginTop: '10px'
          }}
        >
          {giftCards.map((giftCard) => {
            return (
              <Grid item key={giftCard.id} xs={12} md={6}>
                <Box
                  textAlign="center"
                  style={{
                    margin: '10px 0'
                  }}
                >
                  <MonetizingLink
                    href={getProductLink(giftCard.ASIN, tag)}
                    target="_blank"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                      trackLinkClick(e, 'gift-card-image')
                    }
                    clickType="gift-card-image"
                    tag={tag}
                  >
                    <img
                      src={giftCard.image?.replace('http:', 'https:')}
                      alt={giftCard.title}
                    />
                  </MonetizingLink>
                  <Box>
                    <MonetizingLink
                      href={getProductLink(giftCard.ASIN, tag)}
                      target="_blank"
                      clickType="gift-card"
                      tag={tag}
                      underline="always"
                    >
                      {giftCard.title}
                    </MonetizingLink>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
