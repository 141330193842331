import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/system';
import { useGetBrandFilters } from '@hooks/useDeals';
import { DealPostType } from '@types';

const BrandChips = ({
  setSearchFilter,
  currentlyShowingDeals,
  latestDealsLoading
}: {
  setSearchFilter: (value: string) => void;
  currentlyShowingDeals: DealPostType[];
  latestDealsLoading: boolean;
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  // brands is string[] when loaded, undefined otherwise
  const { data: brands, isLoading } = useGetBrandFilters();
  const loading = latestDealsLoading || isLoading;
  const loadingChipStyles = {
    borderRadius: '16px',
    marginRight: '6px',
    display: 'inline-block',
    flexShrink: 0
  };

  const mapOfBrandsWithCount = React.useMemo(() => {
    const map = new Map<string, number>();
    currentlyShowingDeals.forEach((deal) => {
      if (deal.brand) {
        map.set(deal.brand, (map.get(deal.brand) || 0) + 1);
      }
    });
    return map;
  }, [currentlyShowingDeals]);

  const getCountFromBrand = (brand: string) => {
    return mapOfBrandsWithCount.get(brand) || 0;
  };

  const getBrandsWithMoreThanOneDeal = () => {
    return (brands || []).filter((brand) => getCountFromBrand(brand) > 1);
  };

  const displayBrands = React.useMemo(() => {
    return getBrandsWithMoreThanOneDeal();
  }, [brands, mapOfBrandsWithCount]);

  if (!loading && displayBrands.length < 3) {
    return null;
  }

  return (
    <Box
      sx={{
        overflowX: 'auto', // Enable horizontal scrolling
        display: 'flex', // Flex to align the chips
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
        '&::-webkit-scrollbar': {
          display: 'none' // Hide scrollbar in Chrome
        },
        whiteSpace: 'nowrap', // Prevent chips from wrapping
        marginRight: {
          xs: '-17px',
          md: 'initial'
        },
        marginLeft: {
          xs: '-10px',
          md: 'initial'
        }
      }}
    >
      {loading ? (
        // Show multiple loading skeleton chips for better visual effect
        <>
          <Skeleton
            variant="rectangular"
            width="80px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="50px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="65px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="70px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="80px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="90px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="70px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="75px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="85px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="50px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="65px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="70px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="80px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="55px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="85px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="50px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="65px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="70px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="80px"
            height="32px"
            sx={loadingChipStyles}
          />
          <Skeleton
            variant="rectangular"
            width="90px"
            height="32px"
            sx={loadingChipStyles}
          />
        </>
      ) : (
        displayBrands?.map((brand) => (
          <Chip
            avatar={
              <Avatar
                sx={{
                  color: isDarkMode ? '#FFFFFF' : '#141414', // Dynamic text color based on theme
                  bgcolor: isDarkMode ? '#333' : '#EEE' // Background color based on theme
                }}
              >
                {getCountFromBrand(brand)}
              </Avatar>
            }
            key={brand}
            label={brand}
            sx={{ marginRight: '6px' }}
            onClick={() => setSearchFilter(brand)}
            variant="outlined"
          />
        ))
      )}
    </Box>
  );
};

export default BrandChips;
