import colors from '@utils/colors';

export default {
  action: {
    fontSize: '13px !important',
    display: '-webkit-box !important',
    WebkitLineClamp: '2 !important' as any,
    WebkitBoxOrient: 'vertical !important' as any,
    maxHeight: '40px !important' as any,
    overflow: 'hidden',
    // if more than 450px
    '@media (min-width: 48rem)': {
      minHeight: '40px !important' as any
    }
  },
  cardText: {
    textDecoration: 'none',
    color: colors.jdbPurple,

    '&:hover': {
      color: 'black'
    }
  },
  action2Line: {
    display: '-webkit-box !important',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px !important',
    color: '#333',
    fontWeight: '600 !important',

    '@media (max-width: 48rem)': {
      WebkitLineClamp: 'unset !important'
    }
  },
  cardContent: {
    padding: '8px !important',
    display: 'block',
    // is smaller than 450px and larger than 400px
    '@media (max-width: 48rem) and (min-width: 400px)': {
      padding: '8px 8px 0px 8px'
    }
  },
  skeletonCardContent: {
    maxHeight: 'unset !important',
    '@media (max-width: 450px) and (min-width: 400px)': {
      width: '100%'
    }
  },
  outerContentWrapper: {
    paddingBottom: '68px',
    // is smaller than 450px and larger than 400px
    '@media (max-width: 450px) and (min-width: 400px)': {
      display: 'flex'
    }
  },
  cardImage: {
    '@media (max-width: 450px) and (min-width: 400px)': {
      minWidth: '120px',
      display: 'inline-block'
    }
  },
  actionButton: {
    backgroundColor: 'white !important'
  },
  actionButtons: {
    display: 'flex  !important',
    // justifyContent: 'flex-end'
    justifyContent: 'space-evenly !important',
    // justifyContent: 'space-between',
    borderTop: '1px solid #0000001a !important',
    margin: '12px 0 0 0 !important',
    padding: '8px 0 8px 0 !important',
    maxWidth: '100% !important',
    flexDirection: 'unset !important' as any,
    minWidth: 'unset !important' as any,
    minHeight: 'unset !important' as any,
    width: '100% !important' as any,
    position: 'absolute !important' as any,
    bottom: '0 !important' as any
  },
  mobileDate: {
    display: 'inline',
    fontSize: '10px !important',
    color: '#333'
  },
  mobileOnly: {
    display: 'inline-block'
  },
  listPrice: {
    // strike through
    textDecoration: 'line-through',
    marginLeft: '4px !important',
    verticalAlign: 'bottom',
    display: 'inline !important'
  },
  cardRootDb: {
    backFaceVisibility: 'hidden',
    transform: 'translate3d(0, 0, 0)',
    transformStyle: 'preserve-3d',
    display: 'flex !important',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: 'calc(100% - 20px)',
    minWidth: 'calc(100% - 20px)',
    minHeight: '160px',
    textAlign: 'left',
    margin: '8px 8px 0 8px',
    boxSizing: 'border-box',

    // Ensure cards stretch to the same height
    flex: '1 0 auto',

    // mobile view
    '@media (min-width: 450px) and (max-width: 699px)': {
      maxWidth: 'calc(50% - 14px)',
      minWidth: 'calc(50% - 14px)',
      minHeight: 'initial',
      '&:nth-of-type(2n)': {
        marginLeft: 0
      }
    },

    '@media (min-width: 700px) and (max-width: 999px)': {
      maxWidth: 'calc(33% - 10px)',
      minWidth: 'calc(33% - 10px)',
      minHeight: 'initial',
      '&:nth-of-type(3n)': {
        marginLeft: 0
      },
      '&:nth-of-type(3n-1)': {
        marginLeft: 0
      }
    },

    '@media (min-width: 1000px) and (max-width: 1399px)': {
      maxWidth: 'calc(25% - 10px)',
      minWidth: 'calc(25% - 10px)',
      minHeight: 'initial',
      '&:nth-of-type(4n)': {
        marginLeft: 0
      },
      '&:nth-of-type(4n-1)': {
        marginLeft: 0
      },
      '&:nth-of-type(4n-2)': {
        marginLeft: 0
      }
    },

    '@media (min-width: 1400px)': {
      maxWidth: 'calc(20% - 10px)',
      minWidth: 'calc(20% - 10px)',
      '&:nth-of-type(5n)': {
        marginLeft: 0
      },
      '&:nth-of-type(5n-1)': {
        marginLeft: 0
      },
      '&:nth-of-type(5n-2)': {
        marginLeft: 0
      },
      '&:nth-of-type(5n-3)': {
        marginLeft: 0
      }
    }
  },
  bestSellingBorderDB: {
    border: `2px solid ${colors.trending}`
  }
};
