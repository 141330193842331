import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';

interface NavButtonProps {
  disabled: boolean;
  onClick: () => void;
  isPrevious?: boolean;
}

const NavButton = (props: NavButtonProps) => {
  const { disabled, onClick, isPrevious = true } = props;

  const side = isPrevious ? 'left' : 'right';
  return (
    <IconButton
      aria-label={isPrevious ? 'See previous deal' : 'See next deal'}
      size="large"
      disabled={disabled}
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        backgroundColor: 'white',
        color: 'black',
        transform: 'translateY(-50%)',
        '&:hover': {
          backgroundColor: 'white',
          color: 'grey'
        },
        border: '1px solid rgba(57, 46, 80, 0.5)',
        '@media (max-width: 1300px)': {
          [side]: '-40px'
        },
        '@media (max-width: 540px)': {
          [side]: '-20px',
          zIndex: 10
        },
        [side]: 'calc(-1 * (((100vw - 1200px) / 4) + 20px))'
      }}
    >
      {isPrevious ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
    </IconButton>
  );
};

export default NavButton;
